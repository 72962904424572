<template>
  <th-page-wrapper>
    <!-- Form -->
    <page-form
      :key="$route.fullPath"
      @cancel-requested="handleClose"
      @delete-requested="handleDelete"
      @handled-item="refresh"
    />

    <!-- Table -->
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      :show-operations="false"
      no-meta-check
      route-base="/accounting/expense_accounts"
      resource="expenseAccounts"
      :resource-query="resourceQuery"
      :loading-text="loadingText"
      :buttons="computedButtons"
      show-search-filter
      :search-filters="filtersList"
      do-route-filters
      :retry-options="retryOptions()"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import PageForm from './components/form'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.expense_accounts.title')
    }
  },
  components: {
    PageForm
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filtersList: [
        {
          name: 'account_number',
          type: 'resource-select',
          label: this.$t(
            'pages.expense_accounts.all.filters.account_number.label'
          ),
          placeholder: this.$t('common.inputs.placeholders.select'),
          defaultKey: 'fa_account_number',
          appendToBody: true,
          computedFields: ['fa_account_number', 'name'],
          options: {
            resource: 'expenseAccounts'
          },
          formatValue: (value) => {
            if (!this.resources.expenseAccounts) return value
            return (
              this.resources.expenseAccounts.find(
                (item) => item.fa_account_number === value
              )?.name || ' - '
            )
          }
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['accounting:expense_accounts:create']
        }
      ],
      selectedItems: [],
      loadingText: ''
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    isEdit() {
      return this.$route.name === 'accounting-expense-accounts-edit'
    },
    isNew() {
      return this.$route.name === 'accounting-expense-accounts-new'
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    resourceQuery() {
      return {
        query: {
          deleted: false,
          branch: this.currentLocation
        }
      }
    },
    translations() {
      return {
        bank: this.$t(
          'pages.expense_accounts.edit.form.properties.types.bank.label'
        ),
        deposit: this.$t(
          'pages.expense_accounts.edit.form.properties.types.deposit.label'
        ),
        expense: this.$t(
          'pages.expense_accounts.edit.form.properties.types.expense.label'
        ),
        tip: this.$t(
          'pages.expense_accounts.edit.form.properties.types.tip.label'
        )
      }
    },
    headers() {
      return [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'fa_account_number',
          label: this.$t(
            'pages.expense_accounts.all.headers.fa_account_number'
          ),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row, column) => this.translations[row?.type] || '-'
        }
      ]
    }
  },
  watch: {
    currentLocation() {
      this.refresh()
    }
  },
  mounted() {
    if (this.isEdit || this.isNew) {
      this.$thModal.show('expense-accounts')
    }
  },
  methods: {
    handleExport() {},
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    handleClose() {
      this.$router.push({ name: 'accounting-expense-accounts-default' })
    },
    refresh() {
      this.handleClose()
      this.$refs.table.refresh()
    },
    async handleDelete(payload) {
      const confirm = await this.$askToDelete(
        payload.name || payload.fa_account_number || payload.id
      )
      if (confirm) this.deleteAccount(payload)
    },
    async deleteAccount(payload) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.expense_account.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.expense_account.singular')
      })

      try {
        const inst = th.expenseAccounts()
        await inst.delete(payload.id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.expense_accounts.title')
        })
      })
    },
    retryOptions() {
      const loadingMsg = this.$t('common.loading.long')
      return {
        retries: 4,
        onFailedAttempt: (err) => {
          if (err.attemptNumber === 2) {
            this.loadingText = loadingMsg
          }
        }
      }
    }
  }
}
</script>
